const searchOnSelect = async(action, params) => {
    const resp = await Odrazia.get(action, params);
    if (resp.success) {
        const data = resp.data;
        if (data.results && data.results.length > 0) {
            const results = data.results;
            const options = results.map((result) => {
                return {
                    text: result.name,
                    value: result.id
                }
            });

            return options;
            // select.__instance.setData(options);
        }else{
            return [];
            // select.__instance.setData([]);
        }
    }else{
        return [];
        // select.__instance.setData([]);
    }
};

const TabSlimSelect = document.querySelectorAll('.js-select');
TabSlimSelect.forEach((select) => {
    config = {
        select: select,
        events: {
            beforeClose: () => {
                // Before close est déclenché dès que l'utilisateur clique en dehors du select, afterClose est déclenché après que le select soit fermé + animation
                // On déclenche un event "blur" et cancellable
                const event = new CustomEvent('blur', {cancelable: true});
                select.dispatchEvent(event);
            },
        },
    };
    if (select.getAttribute('multiple') !== null) {
        config.settings = {
            showSearch: true,
            maxValuesShown : 3,
            maxValuesMessage: '{number} valeurs selectionées',
            selectAll: true,
            closeOnSelect: false,
            allowDeselect: true,
            searchHighlight: true,
        };
    }else{
        config.settings = {
            showSearch: true,
            searchHighlight: true,
            closeOnSelect: true
        };
    }

    if (select.getAttribute('placeholder') !== null && select.getAttribute('placeholder') !== false) {
        config.settings.placeholderText = select.getAttribute('placeholder');
    }
    if (select.getAttribute('data-placeholder') !== null && select.getAttribute('data-placeholder') !== false) {
        config.settings.placeholderText = select.getAttribute('data-placeholder');
    }

    // Permettre à l'utilisateur d'ajouter une valeur
    if (select.getAttribute('data-addable') !== null && select.getAttribute('data-addable') !== false) {
        config.events = {
            addable: function (value) {
                // On emit un event pour dire que l'utilisateur a ajouté une valeur
                const element = {
                    text: value,
                    value: value.toLowerCase()
                }
                const event = new CustomEvent('addable', {detail: element});
                select.dispatchEvent(event);

                return element;
            }
        }
    }

    // Server side search
    if (select.getAttribute('data-server-search') !== null && select.getAttribute('data-server-search') !== false) {
        select.__event = null;
        select.__oldSearch = null;
        select.__oldDatas = [];
        config.events.search = (search, currentData) => {
            return new Promise(async (resolve, reject) => {
                const action = select.getAttribute('data-server-action');
                if (action === null || action === '') {
                    return reject('Action on select must be defined')
                }
                // if (search.length < 2) {
                //     return reject('Search must be at least 2 characters')
                // }
                clearTimeout(select.__event);
                if (search != select.__oldSearch) {
                    return select.__event = setTimeout(async () => {
                        // On set l'ancienne search pour éviter de faire des appels inutiles
                        select.__oldSearch = search;
                        const limit = select.getAttribute('data-server-limit') !== null ? select.getAttribute('data-server-limit') : 10;
                        // On ajoute un event sur le search du select
                        const additionnalFilters = select.getAttribute('data-filters') !== null ? JSON.parse(select.getAttribute('data-filters')) : {};
                        const filters = {
                            search: search,
                            limit: limit,
                        }
                        Object.assign(filters, additionnalFilters);
                        const options = await searchOnSelect(action, filters);
                        // console.log(options);
                        // select.__instance.setData(options);
                        if (select.getAttribute('multiple') !== null) {
                            const optionGroup = {
                                label: select.getAttribute('data-placeholder') ?? select.getAttribute('placeholder') ?? 'Résultats',
                                options: options,
                                // closable: 'open',
                                selectAll: true,
                            };
                            select.__oldDatas = [optionGroup];
                            resolve([optionGroup]);
                        }else{
                            select.__oldDatas = options;
                            resolve(options);
                        }
                        
                    }, 1500);
                }else{
                    resolve(select.__oldDatas);
                }
            })
            // afterChange: (value) => {
            //     console.log('AFTER CHANGE', value);
            //     return false;
            // }
        }

        window.addEventListener('load', async () => {
            const action = select.getAttribute('data-server-action');
            if (action === null || action === '') {
                return;
            }
            const limit = select.getAttribute('data-server-limit') !== null ? select.getAttribute('data-server-limit') : 10;
            // On ajoute un event sur le search du select
            const additionnalFilters = select.getAttribute('data-filters') !== null ? JSON.parse(select.getAttribute('data-filters')) : {};
            const filters = {
                limit: limit,
            }
            Object.assign(filters, additionnalFilters);
            const options = await searchOnSelect(action, filters);
            let oldSelectedOptions = select.__instance.getSelected();

            if (select.getAttribute('multiple') !== null) {
                const optionGroup = {
                    label: select.getAttribute('data-placeholder') ?? 'Résultats',
                    options: options,
                    // closable: 'open',
                    selectAll: true,
                };
                select.__instance.setData([optionGroup]);
            }else{
                select.__instance.setData(options);
            }

            if (oldSelectedOptions.length > 0) {
                // On transforme les id en integer
                const selectedOptions = [];
                oldSelectedOptions.map((id) => { 
                    const option = isNaN(id) ? id : parseInt(id);
                    selectedOptions.push(option);
                } );
                // false for not triggering the change event
                select.__instance.setSelected(selectedOptions, false);
            }
        });
    }

    // On enregistre le select sur l'élément DOM
    select.__instance = new SlimSelect(config);

    // On vérifie que le select se trouve dans un form
    const form = select.closest('form');
    if (form !== null) {
        // On ajoute un event sur le reset du form
        form.addEventListener('reset', (event) => {
            // On vérifie que le select se trouve dans le form
            if (event.target.contains(select)) {
                select.__instance.setSelected();
            }
        });
    }
});