
const datepickers = document.querySelectorAll('input.js-datepicker');

datepickers.forEach(datepicker => {

    const settings = {
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: 'fr',
        allowInput: false,
        altInputClass: '',
        disableMobile: 'true',
        defaultDate: null,
    };

    // if (datepicker.getAttribute('data-min-date')) {
    //     settings.minDate = datepicker.dataset.minDate;
    // }

    // if (datepicker.dataset.maxDate) {
    //     settings.maxDate = datepicker.dataset.maxDate;
    // }
    if (datepicker.getAttribute('data-picker-mode') && datepicker.getAttribute('data-picker-mode') == 'hours') {
        settings.enableTime = true;
        // settings.noCalendar = false;
        settings.dateFormat = 'Y-m-d H:i';
        settings.altFormat = 'd/m/Y H:i'; 
        settings.time_24hr = true;
    }

    if (datepicker.getAttribute('data-range') && datepicker.getAttribute('data-range') == 'true') {
        settings.mode = 'range';

        // Si on est en mode range, on ajoute un event sur le changement de l'input. Cela viendra modifier les 2 input hidden qui contiennent les dates de début et de fin
        const startAt = document.createElement('input');
        startAt.type = 'hidden';
        startAt.name = 'startAt';
        startAt.value = '';

        const endAt = document.createElement('input');
        endAt.type = 'hidden';
        endAt.name = 'endAt';
        endAt.value = '';

        datepicker.parentNode.insertBefore(startAt, datepicker.nextSibling);
        datepicker.parentNode.insertBefore(endAt, datepicker.nextSibling);
        
        settings.onClose = function(selectedDates, dateStr, instance) {
            // On vient selectionner les 2 input hidden qui contiennent les dates de début et de fin
            // console.log(instance);

            // on vérifie si la period contient "to" ou "au"
            const datesStrs = dateStr.includes(' au ') ? dateStr.split(' au ') : dateStr.split(' to ');
            if(datesStrs.length == 2){
                startAt.value = datesStrs[0];
                endAt.value = datesStrs[1];
            }else{
                startAt.value = '';
                endAt.value = '';
            }
        };
    }

    const dateValue = datepicker.value;
    
    datepicker.__instance = flatpickr(datepicker, settings);

    // On vérifie que le datepicker est une valeur
    if (dateValue != '' && datepicker.getAttribute('data-range') && datepicker.getAttribute('data-range') == 'true') {
        const datesStrs = dateValue.includes(' au ') ? dateValue.split(' au ') : dateValue.split(' to ');
        datepicker.__instance.setDate(datesStrs, false);

        // datepicker.__instance.getDates();

        // On vient aussi définir les valeurs des inputs hidden
        // On récupèer le parent du datepicker
        const parent = datepicker.parentNode;
        const startAt = parent.querySelector('input[name="startAt"]');
        const endAt = parent.querySelector('input[name="endAt"]');
        startAt.value = datesStrs[0];
        endAt.value = datesStrs[1];
    }
});

/**
 * Init period
 */
// flatpickr('#periodSummary', {
//     altFormat: 'd/m/Y',
//     mode: 'range',  
//     altInput: true,
//     dateFormat: 'Y-m-d',
//     locale: 'fr',
//     allowInput: false,
//     altInputClass: '',
//    // appendTo: document.querySelector('.filtre__date'),
//     onClose: function(selectedDates, dateStr, instance){
//         const datesStrs = dateStr.split(' au ');
//         if(datesStrs.length != 2){
//             document.querySelector('#periodSummary')._flatpickr.clear();
//         }
// 		console.log(datesStrs);
//     },
//     disableMobile: 'true'
// });