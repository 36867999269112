const searchModal = document.querySelector('#app_search');

if (searchModal) {
    const containerResults = searchModal.querySelector('#app_search_results');

    const globalSearch = async () => {
        const formData = new FormData(searchModal.querySelector('#app_search_form'));
        containerResults.innerHTML = '<i class="fa-solid fa-circle-notch animate-spin text-primary-main text-xl"></i>';
        const resp = await Odrazia.get("admin_search", {
            'search' : formData.get('search'),
        });
        containerResults.innerHTML = "";
        if (resp.success) {
            const data = resp.data;
            data.results.forEach(element => {
                const li = document.createElement('li');
                const a = document.createElement('a');
                // lists__item flex gap-4 items-center
                li.classList.add('lists__item', 'flex', 'gap-4', 'items-center');
                a.classList.add('btn', 'btn-light', 'flex-grow', 'truncate');

                a.href = element.path;
                // Module
                const icon = document.createElement('i');
                if (element.module) {
                    if (element.module === 'shop') {
                        icon.classList.add('text-secondary-400');
                    }else if (element.module === 'site') {
                        icon.classList.add('text-tertiary-400');
                    }else {
                        icon.classList.add('text-primary-main');
                    }
                }
                // On fait un split sur les espaces 
                const classIcons = element.icon.split(' ');
                icon.classList.add(...classIcons);
                icon.classList.add('mr-2');
                // Title
                const title = document.createElement('span');
                title.innerHTML = element.name;

                // Arrow right
                const arrow = document.createElement('i');
                arrow.classList.add('fas', 'fa-arrow-right', 'ml-auto');

                a.appendChild(icon);
                a.appendChild(title);
                a.appendChild(arrow);

                li.appendChild(a);

                containerResults.appendChild(li);
            });

            if (data.results.length === 0) {
                const li = document.createElement('li');
                li.classList.add('lists__item', 'text-center');
                li.innerHTML = "Aucun résultat pour votre recherche";
                containerResults.appendChild(li);
                return;
            }else{
                // On focus le premier élément
                // containerResults.querySelector('a').focus();
            }
        } else {
            console.error(resp);
            Notifications.Error.fire({
                title: "Erreur",
                text: "Une erreur est survenue lors de la recherche",
            })
        }
    }
    // When the search modal is opened 
    window.addEventListener('open.hs.overlay', () => {
        openSearchModal();
    });
    const openSearchModal = () => {
        // Focus search input -> already done by Preline
        // searchModal.querySelector('input[type=search]').focus(); 
    }
    searchModal.querySelector('#app_search_form').addEventListener('submit', (e) => {
        e.preventDefault();
        e.stopPropagation();
        globalSearch();
    });
    // INIT Shorcuts
    Mousetrap.bind(['ctrl+k', 'meta+k'], (e) => {
        e.preventDefault();
        e.stopPropagation();
        // On check
        // Define new event
        if (!searchModal.classList.contains('open')) {
            HSOverlay.open(searchModal);
        }
        // On focus input
        searchModal.querySelector('input[type=search]').focus();
    });

    // ON keydown wth timeout 
    // let timeout = null;
    // searchModal.querySelector('input[type=search]').addEventListener('keydown', (e) => {
    //     if (timeout) {
    //         clearTimeout(timeout);
    //     }
    //     timeout = setTimeout(() => {
    //         globalSearch();
    //     }, 500);
    // });
}

/**
 * CTRL + S / CMD + S
 */
const searchInput = document.querySelector('input#search');
document.addEventListener("keydown", (e) => {
    if (e.key === "f" && (e.ctrlKey || e.metaKey)) {
        if (searchInput) {
            searchInput.focus();
            e.preventDefault();
            e.stopPropagation();
        }
    }
});