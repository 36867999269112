/**
 * CONTENTS
 * Permet de gérer les contenus des éléments qui utilise la structure ElementContent
 * js-contents-container : Container pour 1 element qui contient plusieurs contenus
 */

const containerElementsContents = document.querySelectorAll(".js-contents-container");

const defineElementEvents = (container, element) => {
    const btnDelete = element.querySelector("button.js-btn-delete-content");

    if (btnDelete) {
        btnDelete.addEventListener("click", (e) => {
            e.preventDefault();
            // On supprime tous les éléments qui ont le meme id (Pour les autres langues)
            const elements = container.querySelectorAll(`#${element.id}`);
            elements.forEach(element => {
                element.remove();
            });
        });
    }

    const inputName = element.querySelector("input[name*='name']");
    const button = element.querySelector("button.hs-accordion-toggle span.js-title");
    inputName.addEventListener("input", (e) => {
        const value = e.target.value;
        button.innerHTML = value;
    });    
};

const createContentElement = (container, options = {}) => {

    const type = options.hasOwnProperty("type") ? options.type : "TEXT";
    
    const cloneContents = container.querySelector(".js-contents-clone");

    let totalElements = container.querySelector(".js-contents-list");
    totalElements = totalElements.querySelectorAll(".js-contents-items").length ?? 0;
    totalElements = totalElements + 1;
    const wantFocus = options.hasOwnProperty("focus") ? options.focus : true;

    const element = document.createElement("li");
    element.classList.add("js-contents-items","hs-accordion","accordions__items");
    let randomId = 'content-'+ Math.floor(Math.random() * 1000) + '-'+ Math.floor(Math.random() * 1000);
    element.setAttribute("id", randomId);

    const button = document.createElement("button");
    button.classList.add("hs-accordion-toggle","accordions__head","accordions__head-outline");
    button.setAttribute("type", "button");
    button.setAttribute("aria-expanded", "false");
    button.setAttribute("aria-controls", randomId + "-collapse");
    const btnName = options.hasOwnProperty("name") ? options.name : "";
    button.innerHTML = `
        <div class="flex gap-2"><span class="js-type">(${type})</span><span class="js-title">${btnName}</span></div><i class="hs-accordion-active:rotate-180 fa-solid fa-chevron-down transition-all"></i>
    `;

    // On clone le contenu
    const div = cloneContents.cloneNode(true);
    div.classList.remove("hidden");
    div.setAttribute("id", randomId + "-collapse");
    div.setAttribute("aria-labelledby", randomId);

    element.appendChild(button);
    element.appendChild(div);

    // On selectionne le "name"
    const inputName = div.querySelector("input[name*='name']");
    inputName.value = options.hasOwnProperty("name") ? options.name : "";
    const inputCode = div.querySelector("input[name*='code']");
    inputCode.value = options.hasOwnProperty("code") ? options.code : "";
    const inputPosition = div.querySelector("input[name*='position']");
    inputPosition.value = totalElements;

    // On supprime les inputs contents qu'on n'a pas besoin
    const inputsContentMultiple = div.querySelectorAll("[id*='clone-content']");
    inputsContentMultiple.forEach(input => {
        if (!input.getAttribute('id').toUpperCase().includes(type)) {
            input.remove();
        }else if (input.getAttribute('id').toUpperCase().includes(type) && type == "MEDIA") {
            // On retire le blockeur d'initialisation de l'uploader
            const uploadContainer = input.querySelector('.js-upload-files.js-uppy-initialized');
            if (uploadContainer) {
                uploadContainer.classList.remove('js-uppy-initialized');
            }
        }
    });

    // On créer un input qui va contenir le type de contenu
    const inputType = document.createElement("input");
    inputType.setAttribute("type", "hidden");
    inputType.setAttribute("name", "contents["+totalElements+"][type]");
    inputType.value = type;
    div.appendChild(inputType);

    // On change la valeur du name des inputs
    const inputs = div.querySelectorAll("input[name*='clone'], .field__textarea");
    inputs.forEach(input => {
        const name = input.getAttribute("name");
        if (name) {
            input.setAttribute("name", name.replace("clone", 'contents['+totalElements+']'));
        }else{
            const dataName = input.getAttribute("data-name");   
            input.setAttribute("data-name", dataName.replace("clone", 'contents['+totalElements+']'));
        }
    });
    // On ajoute les events
    defineElementEvents(container, element);

    // On ajoute l'élément dans le container
    i = 0;
    const containerFieldsLanguages = container.querySelectorAll(".js-contents-list-fields");
    containerFieldsLanguages.forEach(containerLanguage => {
        if (i == 0) {
            containerLanguage.appendChild(element);
            if ((!container.classList.contains("hidden") || container.closest(".hidden") === null) && wantFocus) {
                element.querySelector("button").click();
                element.querySelector("input[name*='name']").focus();
            }
        }else{
            // On clone l'élément
            const cloneElement = element.cloneNode(true);
            containerLanguage.appendChild(cloneElement);
            if ((!container.classList.contains("hidden") || container.closest(".hidden") === null) && wantFocus) {
                cloneElement.querySelector("button").click();
                cloneElement.querySelector("input[name*='name']").focus();
            }
        }
        i++;
    });

    const textareas = container.querySelectorAll(".field__textarea.js-editor-not-loaded");
    textareas.forEach(textarea => {
        // ne rien faire si textArea dans productContentClone
        if (!textarea.getAttribute("data-name").includes("clone")) {
            textarea.classList.remove("js-editor-not-loaded");
            textarea.classList.add("js-editor");

            textarea.setAttribute("id", "editor-" + Math.floor(Math.random() * 1000) + "-" + Math.floor(Math.random() * 1000));
            //Global Method
            TextEditor.new(textarea);
        }
    });

    // if (type == "MEDIA") {
    //     // On emet un event sur le body 
    //     const body = document.querySelector("body");
    //     body.dispatchEvent(new CustomEvent("init-uppy"));
    // }
};

containerElementsContents.forEach(container => {

    const enabledType = container.getAttribute("data-types");
    const tabTypes = enabledType.split(",");
    const btnAddContent = container.querySelectorAll("button.js-btn-add-content");

    const existingElements = container.querySelectorAll(".js-contents-list .js-contents-items");
    existingElements.forEach(element => {
        defineElementEvents(container, element);
    });
        
    btnAddContent.forEach(btn => {
        btn.addEventListener("click", async (e) => {
            // On crée un nouvelle élément dans la list des contenus
            e.preventDefault();
            const haveContent = container.querySelector(".js-contents-list .js-contents-items");
            if (tabTypes.length > 1) {
                // On display une modal pour choisir le type de contenu
                const options = [];

                tabTypes.forEach((type) => {
                    options[type] = type;
                });

                const select = await Notifications.Default.fire({
                    title: "Type de contenu",
                    text: " ",
                    input: 'radio',
                    inputOptions: options,
                    inputValidator: (value) => {
                        if (!value) {
                            return "Vous devez choisir un type de contenu";
                        }
                    },
                    cancelButtonText: "Annuler",
                    confirmButtonText: "Ajouter",
                    showCancelButton: true,
                    showConfirmButton: true,
                });
                if (select.value) {
                    if (!haveContent) {
                        createContentElement(container , {
                            'type' : select.value ?? 'TEXT',
                            'name' : 'Caractéristiques',
                            'code' : 'caracteristiques',
                            'focus' : true,
                        });
                    }else{
                        createContentElement(container, {
                            'type' : select.value ?? 'TEXT',
                            'focus' : true,
                        });
                    }
                }
            }else{
                if (!haveContent) {
                    createContentElement(container , {
                        'type' : tabTypes[0] ?? 'TEXT',
                        'name' : 'Caractéristiques',
                        'code' : 'caracteristiques',
                        'focus' : true,
                    });
                }else{
                    createContentElement(container, {
                        'type' : tabTypes[0] ?? 'TEXT',
                        'focus' : true,
                    });
                }
            }
            
        });
    });
});


