
Notifications = (function () {
    const Loading = Swal.mixin({
        title: 'Chargement...',
        icon: 'info',
        didOpen: () => {
            Swal.showLoading()
        }
    });
    const Toast = Swal.mixin({
        toast: true,
        icon: 'success',
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('click', Swal.close)
        }
    });
    const Confirm = Swal.mixin({
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Non',
        confirmButtonColor: 'var(--color1-500)',
        cancelButtonColor: 'var(--color-warning)',
        confirmButtonText: 'Oui'
    });
    const Error = Swal.mixin({
        title: 'Erreur !',
        text: 'Une erreur est survenue',
        icon: 'error',
        confirmButtonColor: 'var(--color1-500)',
    });
    const Success = Swal.mixin({
        icon: 'success',
        timer: 2000,
        timerProgressBar: true,
        confirmButtonColor: 'var(--color1-500)',
    });
    const Default = Swal.mixin({
        // icon: 'success',
        title: '',
        text: '',
        confirmButtonColor: 'var(--color1-500)',
        confirmButtonText: 'Ok'
    });
    return { 
        Default: Default,
        Loading: Loading,
        Success: Success,
        Toast: Toast,
        Error: Error,
        Confirm: Confirm
    };
})();
  
  // Notifications.Loading.fire();
  // Notifications.Success.fire();
  // Notifications.Error.fire();
  // Notifications.Confirm.fire();
  // Notifications.Toast.fire();